import PropTypes from 'prop-types';

const ProgressBar = ({ current, tiers, label = 'members' }) => {
  const validTiers = tiers.filter((tier) => tier > current);
  // A default that will at least make the gauge look reasonable correct
  // in cases where we bust through the provided range.
  const nextTier =
    validTiers.sort((a, b) => {
      a > b;
    })[0] || Math.ceil(current * 1.25);
  return (
    <>
      <progress
        value={current}
        max={nextTier}
        className="w-full [&::-moz-progress-bar]:bg-stone-200 dark:[&::-moz-progress-bar]:bg-stone-700 [&::-moz-progress-value]:bg-gradient-to-r [&::-moz-progress-value]:from-indigo-500 [&::-moz-progress-value]:via-purple-500 [&::-moz-progress-value]:to-pink-500 [&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-bar]:bg-stone-200 dark:[&::-webkit-progress-bar]:bg-stone-700 [&::-webkit-progress-value]:rounded-lg [&::-webkit-progress-value]:bg-gradient-to-r [&::-webkit-progress-value]:from-indigo-500 [&::-webkit-progress-value]:via-purple-500 [&::-webkit-progress-value]:to-pink-500"
      />
      <p className="text-left">
        <strong>{`${current.toLocaleString()} so far! `}</strong>
        {`Help us get to ${nextTier.toLocaleString()} ${label}!`}
      </p>
    </>
  );
};

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  tiers: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.string,
};

export default ProgressBar;
