import PropTypes from 'prop-types';
import Img from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';

import Anchor from 'components/typography/anchor';
import client from 'lib/sanity';
import { MerchType, SanityMerchImageType } from 'utilities/propTypes';

import config from 'siteconfig';

export const MerchItem = ({ image, href }) => {
  const imageProps = useNextSanityImage(client, image);
  return (
    <Anchor href={href} label={image.altText}>
      <Img
        {...imageProps}
        {...{ alt: image.altText }}
        // Sizes under 640, make it ~50% viewport, over that, 225px intrinsic (browser accounts for retina)
        sizes="(max-width: 640px) 50vw, 225px"
        style={{ objectFit: 'contain' }}
        blurDataURL={image.asset.metadata.lqip}
        placeholder="blur"
        className="size-40"
      />
    </Anchor>
  );
};

export const MerchItems = ({ items }) => {
  return (
    <div className="flex flex-row flex-wrap justify-center">
      {items.map((item) => (
        <MerchItem image={item.image} href={item.link} key={item._id} />
      ))}
    </div>
  );
};

const Merch = ({ items }) => {
  return (
    <div className="text-center">
      <h2 id="merch">Wear the Bot on Your Sleeve</h2>
      <p className="mx-auto my-4 max-w-prose">
        Head to{' '}
        <Anchor className="anchor-text" href={config.shopUrls.cottonBureau}>
          Cotton Bureau
        </Anchor>{' '}
        or{' '}
        <Anchor className="anchor-text" href={config.shopUrls.myFanThreads}>
          My Fan Threads
        </Anchor>{' '}
        for shirts, hoodies, tanks, joggers, pins, patches, buttons, stickers,
        and more stuff we put the bot&nbsp;on. Or{' '}
        <Anchor className="anchor-text" href="/premium">
          go premium
        </Anchor>{' '}
        for our members-only&nbsp;pin!
      </p>

      <MerchItems items={items} />
    </div>
  );
};

MerchItem.propTypes = {
  image: SanityMerchImageType,
  href: PropTypes.string.isRequired,
};

MerchItems.propTypes = {
  items: PropTypes.arrayOf(MerchType),
};

Merch.propTypes = {
  items: PropTypes.arrayOf(MerchType),
};

export default Merch;
