import cx from 'classnames';

const Divider = ({ className }) => {
  return (
    <hr
      className={cx(
        'my-10 h-px border-0 bg-stone-200 dark:bg-stone-700',
        className
      )}
    />
  );
};

export default Divider;
