import { number } from 'prop-types';

import Counter from './Counter';

const BotStatsCounters = ({ lettersSent, users, voterRegistrations }) => {
  return (
    <section className="my-10 flex flex-row flex-wrap justify-evenly gap-x-1 gap-y-4">
      <Counter count={lettersSent} text="Letters Sent" />
      <Counter count={users} text="Members" />
      <Counter count={voterRegistrations} text="Voters Protected" />
    </section>
  );
};

BotStatsCounters.propTypes = {
  lettersSent: number.isRequired,
  users: number.isRequired,
  voterRegistrations: number.isRequired,
};

export default BotStatsCounters;
