import PropTypes from 'prop-types';

const Counter = ({ count, text }) => {
  return (
    <div className="flex flex-col items-center gap-2 text-center">
      <div className="flex max-w-fit divide-x divide-black/20 rounded border-4 border-white/5 bg-amber-400 text-2xl font-bold text-black/70 shadow dark:bg-zinc-800 dark:text-amber-400">
        {count
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          .split('')
          .map((letter, index) => (
            <span className="w-7 leading-10" key={`${letter}-${index}`}>
              {letter}
            </span>
          ))}
      </div>
      <span className="text-sm uppercase tracking-wider">{text}</span>
    </div>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default Counter;
