import PropTypes from 'prop-types';
import Img from 'next/image';

import useMediaQuery from 'hooks/useMediaQuery';
import { getPostsForHome } from 'lib/api/posts';
import { getMerch } from 'lib/api/merch';
import { getSiteStats } from 'lib/api/stats';
import { TIME_UNIT } from 'lib/api/themis/constants';
import { getRepTotals, getTrendingPetitions } from 'lib/api/themis/queries';

import { NationalCharts } from 'components/delivery-charts/Charts';
import Layout from 'components/layout';
import AuthorQuote from 'components/authorquote';
import BotImage from 'components/botimage';
import Divider from 'components/divider';
import Bubble from 'components/bubble';
import ResistCTAButtons from 'components/buttons/cta';
import Button from 'components/buttons/button';
import ProgressBar from 'components/progressBar';
import Merch from 'components/merch';
import BotStatsCounters from 'components/counter/BotStatsCounters';
import { Petition } from 'components/cards';
import Anchor from 'components/typography/anchor';

import {
  BlogPostTileType,
  SiteStatsType,
  MerchType,
  CampaignTypeList,
} from 'utilities/propTypes';

import snopes from 'images/press/snopes.svg';
import khou from 'images/press/khou.svg';
import recode from 'images/press/recode.svg';
import teenVogue from 'images/press/teen-vogue.svg';
import engadget from 'images/press/engadget.svg';
import fastCompany from 'images/press/fast-company.svg';
import guardian from 'images/press/guardian.svg';
import huffingtonPost from 'images/press/huffington-post.svg';
import good from 'images/press/good.svg';
import bustle from 'images/press/bustle.svg';
import lifehacker from 'images/press/lifehacker.svg';
import miamiHerald from 'images/press/miami-herald.svg';
import theSkimm from 'images/press/theskimm.svg';
import wusa from 'images/press/wusa.svg';
import kdka from 'images/press/kdka.svg';
import inStyle from 'images/press/instyle.svg';
import businessInsider from 'images/press/bi.svg';
import romper from 'images/press/romper.svg';
import bna from 'images/press/bloomberg.svg';
import arizonaRepublic from 'images/press/arizona-republic.svg';
import inc from 'images/press/inc.svg';
import adweek from 'images/press/adweek.svg';
import billboard from 'images/press/billboard.svg';
import nme from 'images/press/nme.svg';
import newsday from 'images/press/newsday.svg';
import salon from 'images/press/salon.svg';
import oprah from 'images/press/oprah.svg';
import mailbot from 'images/bots/mailbot.svg';
import lovebot from 'images/bots/lovebot.svg';
import ruth from 'images/bots/ruth.svg';
import rosie from 'images/bots/rosie.svg';
import appStore from 'images/app-store.svg';
import appStoreDark from 'images/app-store-dark.svg';
import newsThumb from 'images/social-thumb.jpg';
import loopImg from 'images/loops.png';
import loopImgDark from 'images/loops-dark.png';
import voteSeal from 'images/vote-seal.svg';
import voteSealDark from 'images/vote-seal-dark.svg';
import frontPages from 'images/newspapers.png';
import capitolHero from 'images/capitol-only.svg';
import capitolLetters from 'images/capitol-letters.svg';

import {
  FATHOM_SIGNUP_EVENT_FB_MESSENGER,
  FATHOM_SIGNUP_EVENT_IMESSAGE,
  FATHOM_SIGNUP_EVENT_INSTA,
  FATHOM_SIGNUP_EVENT_SMS,
  FATHOM_SIGNUP_EVENT_TELEGRAM,
  FATHOM_SIGNUP_EVENT_WHATSAPP,
} from 'utilities/analytics';
import config from 'siteconfig';

const REVALIDATE_TIME_MINUTES = 5;
const REVALIDATE_TIME_SECONDS = REVALIDATE_TIME_MINUTES * 60;

export const getStaticProps = async ({ preview = false }) => {
  const siteStatsQuery = getSiteStats({ preview });
  const postsQuery = getPostsForHome({ count: 3, preview });
  const trendingPetitionsQuery = getTrendingPetitions({
    since: 7,
    period: TIME_UNIT.DAY,
    limit: 2,
  });
  const merchQuery = getMerch({ preview });
  const repTotalsQuery = getRepTotals();

  const responses = await Promise.all([
    siteStatsQuery,
    postsQuery,
    trendingPetitionsQuery,
    merchQuery,
    repTotalsQuery,
  ]);

  return {
    props: {
      siteStats: responses[0],
      posts: responses[1],
      trendingPetitions: responses[2],
      merch: responses[3],
      nationalData: responses[4],
      preview,
    },
    revalidate: REVALIDATE_TIME_SECONDS,
  };
};

const Banner = () => {
  return (
    <div className="flex flex-col gap-4 pb-10">
      <Img
        src={capitolLetters}
        fill={true}
        alt="An illustration of letters flying at the U.S. Capitol"
        priority={true}
        className="-z-10 dark:mix-blend-color-dodge"
      />
      <h1 className="tracking-tight sm:text-3xl">
        Contact all your officials at&nbsp;once.
      </h1>
      <section className="relative h-[36vw] max-h-96">
        <Img
          src={capitolHero}
          alt="An illustration of the U.S. Capitol complex"
          priority={true}
          fill={true}
          className=""
        />
      </section>
      <p className="text-xl tracking-tight">
        Resistbot is a chatbot that turns your texts into faxes, postal mail, or
        emails to your representatives in&nbsp;minutes.
      </p>
      <ResistCTAButtons copy="Chat with Resistbot" />
    </div>
  );
};

const Home = (props) => {
  const { merch, trendingPetitions, nationalData } = props;
  const {
    lettersSent,
    voterRegistrations,
    users,
    recurringDonors,
    targetRecurringDonors,
  } = props.siteStats;

  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <Layout
      title="Resistbot"
      description="Text RESIST to 50409 to contact your federal, state, or local elected officials; create movements; and more. Over 30,000,000 letters delivered and counting."
      header={<Banner />}
      canonicalPath="/"
    >
      <section className="mx-auto flex max-w-prose flex-col flex-nowrap gap-4 text-center">
        <BotImage src={rosie.src} priority={true} />
        <h2>The Easiest Way to Be&nbsp;Heard</h2>
        <p>
          Send the word <Bubble type="sms">resist</Bubble> to Resistbot on{' '}
          <Anchor
            className="anchor-text"
            href={config.channelUrls.apple + 'resist'}
            eventName={FATHOM_SIGNUP_EVENT_IMESSAGE}
          >
            Apple Messages
          </Anchor>
          ,{' '}
          <Anchor
            className="anchor-text"
            href={config.channelUrls.whatsapp + 'resist'}
            eventName={FATHOM_SIGNUP_EVENT_WHATSAPP}
          >
            WhatsApp
          </Anchor>
          ,{' '}
          <Anchor
            className="anchor-text"
            href={config.channelUrls.messenger + 'resist'}
            eventName={FATHOM_SIGNUP_EVENT_FB_MESSENGER}
          >
            Messenger
          </Anchor>
          ,{' '}
          <Anchor
            className="anchor-text"
            href={config.channelUrls.instagram}
            eventName={FATHOM_SIGNUP_EVENT_INSTA}
          >
            Instagram
          </Anchor>
          ,{' '}
          <Anchor
            className="anchor-text"
            href={config.channelUrls.telegram + 'resist'}
            eventName={FATHOM_SIGNUP_EVENT_TELEGRAM}
          >
            Telegram
          </Anchor>
          , or by text to{' '}
          <Anchor
            className="anchor-text"
            href={config.channelUrls.sms + 'resist'}
            eventName={FATHOM_SIGNUP_EVENT_SMS}
          >
            50409
          </Anchor>{' '}
          and answer the questions texted back. In minutes, you’ll have
          contacted Congress or your other elected officials.{' '}
          <Anchor className="anchor-text" href="/letters">
            Make your letter open
          </Anchor>{' '}
          for maximum effect.
        </p>
        <p className="text-xs">
          Message and data rates may apply if you use SMS. Message frequency
          varies. Text <Bubble>stop</Bubble> to 50409 to stop all messages. Text{' '}
          <Bubble>help</Bubble> to 50409 for help. See our{' '}
          <Anchor href="/terms" className="anchor-subtle">
            terms&nbsp;of&nbsp;use
          </Anchor>
          ,{' '}
          <Anchor href="/privacy" className="anchor-subtle">
            privacy&nbsp;notice
          </Anchor>
          , and{' '}
          <Anchor href="/rights" className="anchor-subtle">
            user bill of&nbsp;rights
          </Anchor>
          .
        </p>
        <div className="iphone-container">
          <p className="max-w-56 rounded-lg bg-white p-4 text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <span role="img" aria-label="Newspaper">
              📰
            </span>{' '}
            Send me an article to teach me about your issue!
          </p>
          <p className="max-w-48 self-end overflow-hidden rounded-lg bg-white text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <Img
              alt="Photograph of Louis Dejoy"
              src={newsThumb}
              priority={false}
              height={160}
              width={240}
            />

            <span className="block p-4 pb-0">
              DeJoy’s 10-Year Plan Could Gut USPS. He Doesn’t Want You to Know
              the Details.
            </span>
            <span className="block p-4 pt-0 text-stone-500 dark:text-stone-400">
              truthout.org
            </span>
          </p>
          <p className="max-w-56 rounded-lg bg-white p-4 text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <span role="img" aria-label="Sparkles">
              ✨
            </span>{' '}
            Got it, here’s your letter:
            <br />
            <br />
            The U.S. Postal Service’s 10-year plan, led by Postmaster General
            Louis DeJoy, raises significant concerns. The proposed consolidation
            of mail sorting and delivery into regional centers threatens smaller
            post offices with potential closure or reduced hours. This lack of
            transparency and resistance…
          </p>
        </div>

        <p>
          You don’t have to find your representatives first, Resistbot will do
          it for you. It can even write your letter from a bill number or news
          article, or you can type a letter&nbsp;yourself.
        </p>

        <AuthorQuote
          author="@zombiezeni"
          position="Resistbot Member"
          href="https://www.tiktok.com/@zombiezeni/video/7214222550015020330"
        >
          Raise your hand if you would rather go get a cavity filled than make
          four calls and leave four voicemails… thank goodness there is a
          better&nbsp;way.
        </AuthorQuote>
      </section>
      <Divider />
      <h2 className="text-center">Join a&nbsp;Campaign</h2>
      <p className="mb-4 text-center">
        Not sure what to say? Join one of our organizers’ campaigns!
      </p>
      <div className="mb-10 flex flex-col gap-1">
        {trendingPetitions.map((petition) => (
          <Petition key={petition.petition_code} petition={petition} />
        ))}

        <Button theme="outline" href={`/petitions`}>
          See Trending Campaigns
        </Button>
      </div>
      <section className="mx-auto flex max-w-prose flex-col flex-nowrap gap-4 text-center">
        <BotImage src={lovebot.src} priority={false} />
        <h2>Upgrade for a More Powerful&nbsp;Bot</h2>
        <p className="mb-4">
          We rely on your support,{' '}
          <Anchor className="anchor-subtle" href="/rights">
            not your data
          </Anchor>
          , to operate. Premium members get unlimited use of our AI-powered
          tools like letter writing and bill summaries, physical delivery of
          letters via postal mail or fax, daily newspaper front pages, and{' '}
          <Anchor className="anchor-subtle" href="/premium">
            much more
          </Anchor>
          .
        </p>
        <Button theme="animated" href="/premium" className="shrink">
          Upgrade to Premium
        </Button>
        <ProgressBar
          current={recurringDonors}
          tiers={[targetRecurringDonors]}
        />

        <AuthorQuote
          author="Democrats Abroad"
          href="https://www.democratsabroad.org/activism_from_abroad_resistbot"
          className="mt-4"
        >
          Resistbot is a well-designed, powerful easy-to-use tool that allows
          you to communicate your thoughts, wishes, complaints,
          congratulations&mdash;anything that you care to share&mdash;to your
          elected&nbsp;representatives.
        </AuthorQuote>
      </section>
      <Divider />
      <section className="flex flex-col flex-nowrap gap-4 text-center">
        <BotImage src={mailbot.src} />
        <h2>Create Your Own Campaigns in&nbsp;Minutes</h2>
        <p className="mx-auto max-w-prose">
          Any{' '}
          <Anchor className="anchor-text" href="/letters">
            letter you text in
          </Anchor>{' '}
          can be turned into{' '}
          <Anchor className="anchor-text" href="/petitions">
            a campaign
          </Anchor>{' '}
          that can drive millions of emails, faxes, or first-class mail to every
          member of Congress or state lawmaker, not just your own. Unlike
          typical petitions, Resistbot delivers letters to each signer’s
          officials instantly, you don’t have to deliver yourself.{' '}
          <Anchor href="https://medium.com/visible-hands/from-zero-to-cuomos-desk-in-two-weeks-237dd93c894a">
            With effort, they grow&nbsp;quickly!
          </Anchor>
        </p>

        <div className="relative h-[50vw] max-h-96">
          {prefersDark ? (
            <Img
              src={loopImgDark}
              alt="Diagram of how Resistbot campaigns grow to create more signers and more deliveries over time"
              fill
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <Img
              src={loopImg}
              alt="Diagram of how Resistbot campaigns grow to create more signers and more deliveries over time"
              fill
              style={{ objectFit: 'contain' }}
            />
          )}
        </div>

        <ol className="flex flex-wrap gap-4 text-left text-sm">
          <li
            className="basis-[calc(50%-16px)] before:mb-1 before:block before:size-6 before:rounded-full before:bg-pink-500 before:text-center before:font-extrabold before:leading-6 before:text-white before:content-[attr(counter)] sm:basis-[calc(25%-16px)]"
            counter="1"
          >
            Campaigns start with <strong>announcement texts</strong> to
            your&nbsp;followers
          </li>
          <li
            className="basis-[calc(50%-16px)] before:mb-1 before:block before:size-6 before:rounded-full before:bg-purple-500 before:text-center before:font-extrabold before:leading-6 before:text-white before:content-[attr(counter)] sm:basis-[calc(25%-16px)]"
            counter="2"
          >
            Each sign drives <strong>letters</strong> to their&nbsp;officials
          </li>
          <li
            className="basis-[calc(50%-16px)] before:mb-1 before:block before:size-6 before:rounded-full before:bg-blue-500 before:text-center before:font-extrabold before:leading-6 before:text-white before:content-[attr(counter)] sm:basis-[calc(25%-16px)]"
            counter="3"
          >
            Signers <strong>fund texts</strong> to members,{' '}
            <strong>share</strong> the campaign &amp; <strong>invite</strong>{' '}
            others
          </li>
          <li
            className="basis-[calc(50%-16px)] before:mb-1 before:block before:size-6 before:rounded-full before:bg-teal-300 before:text-center before:font-extrabold before:leading-6 before:text-white before:content-[attr(counter)] sm:basis-[calc(25%-16px)]"
            counter="4"
          >
            Signers <strong>follow you</strong> and are texted when your next
            campaign starts
          </li>
        </ol>

        <div className="mt-8 flex flex-col flex-nowrap gap-8 sm:flex-row sm:gap-4">
          <AuthorQuote
            author="Ben Wikler"
            position="Chair"
            station="Wisc. Democratic Party"
            className="flex-1"
          >
            Resistbot has an unparalleled and unprecedented power to move
            thousands upon thousands of people to action&nbsp;—&nbsp;instantly.
          </AuthorQuote>

          <AuthorQuote
            author="Kit Wu"
            position="The movement to repeal 50-a in N.Y."
            href="https://medium.com/visible-hands/from-zero-to-cuomos-desk-in-two-weeks-237dd93c894a"
            className="flex-1"
          >
            The final count of constituents who sent an email to their state
            legislator was close to 146,000, and we wouldn’t have been anywhere
            close to that were it not for your efforts… This campaign would not
            [have been] possible without&nbsp;Resistbot.
          </AuthorQuote>
        </div>
      </section>
      <Divider />
      <section className="mx-auto flex flex-col flex-nowrap gap-4 text-center">
        <h2>We Can Do&nbsp;It!</h2>
        <p>Thirty million letters delivered and&nbsp;counting.</p>

        <NationalCharts data={nationalData} />
        <BotStatsCounters {...{ lettersSent, users, voterRegistrations }} />
      </section>

      <section className="mx-auto flex max-w-prose flex-col flex-nowrap gap-4 text-center">
        {prefersDark ? (
          <Img
            src={voteSealDark}
            alt="Diagram of how Resistbot campaigns grow to create more signers and more deliveries over time"
            height="200"
            width="200"
            className="mx-auto"
          />
        ) : (
          <Img
            src={voteSeal}
            alt="Diagram of how Resistbot campaigns grow to create more signers and more deliveries over time"
            height="200"
            width="200"
            className="mx-auto"
          />
        )}

        <p>
          Text <Bubble>Check</Bubble> to Resistbot and we will protect your
          registration, for free, as long as we stay online. This feature
          launched in 2018 and we’re still the only organization that does it,
          in the face of{' '}
          <Anchor
            className="anchor-subtle"
            href="https://www.democracydocket.com/analysis/in-seven-states-removing-voters-from-the-rolls-just-got-easier/"
          >
            endless voter roll purges
          </Anchor>{' '}
          across the&nbsp;country.
        </p>

        <AuthorQuote
          author="Jess Craven"
          position="Chop Wood, Carry Water"
          href="https://www.tiktok.com/@jesscraven101/video/7151808543249747246"
        >
          Not only will Resistbot check your voter registration… once they have
          confirmed you are registered to vote they will check it once a month
          to make sure that you are not purged from the voter rolls and if
          anything changes they will send you a text… I think everyone should
          use&nbsp;it.
        </AuthorQuote>
      </section>
      <Divider />
      <section className="flex flex-col flex-nowrap gap-4 text-center">
        <BotImage src={ruth.src} />
        <h2>Real World Impact</h2>
        <p className="mx-auto max-w-prose">
          Our impact was proven in three studies across 11 elections. Resistbot
          users are reminded and able to access polling locations without
          leaving the chat conversation, as well as access local election
          offices for help, and rich voting information. Your dollar goes
          farther with us than any other turnout program.{' '}
          <Anchor className="anchor-text" href="/premium">
            Become a member
          </Anchor>{' '}
          or{' '}
          <Anchor className="anchor-text" href={config.donateUrls.stripe}>
            donate
          </Anchor>{' '}
          to support our&nbsp;work.
        </p>
        <div className="iphone-container">
          <p className="max-w-56 rounded-lg bg-white p-4 text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <span role="img" aria-label="Reminder Ribbon">
              🎗️
            </span>{' '}
            You have an election next Tuesday! You pledged your support to
            Sherrod Brown. Do you need to know where your polling place is?
          </p>
          <p className="max-w-56 self-end rounded-lg bg-blue-600 p-4 text-xs text-white shadow">
            <span role="img" aria-label="Thumbs Up">
              👍
            </span>{' '}
            Yes, please!
          </p>
          <p className="max-w-56 rounded-lg bg-white p-4 text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <span role="img" aria-label="Ballot Box with Ballot">
              🗳️
            </span>{' '}
            I found 8 early voting sites, 3 polling places for Election Day, and
            15 drop boxes, which do you want to see?
          </p>
        </div>
        <div className="flex flex-col flex-nowrap gap-8 sm:flex-row sm:gap-4">
          <AuthorQuote
            author="Analyst Institute"
            href="https://resistbot-assorted-public.s3-us-west-2.amazonaws.com/analyst-institute.pdf"
          >
            Resistbot’s GOTV program was highly cost-effective and able to
            generate an impressive number of net voters, surpassing the
            performance of many other programs in midterm election&nbsp;cycles.
          </AuthorQuote>
          <AuthorQuote
            author="Christopher B. Mann, Ph.D."
            href="https://resistbot-assorted-public.s3-us-west-2.amazonaws.com/wisconsin-gotv.pdf"
          >
            [Resistbot] increased turnout by 1.8 percentage points in a 2019
            election
          </AuthorQuote>
          <AuthorQuote
            author="Christopher B. Mann, Ph.D. & Scott L. Minkoff, Ph.D."
            href="https://resistbot-assorted-public.s3.us-west-2.amazonaws.com/2019-2020-turnout-analysis.pdf"
          >
            With an estimated treatment effect of… as much as 2 percentage
            points in 2020… messaging [Resistbot’s] user base can boost turnout.
          </AuthorQuote>
        </div>
      </section>
      <Divider />
      <section className="mx-auto flex flex-col flex-nowrap gap-4 text-center">
        <h2>We’ve Met the&nbsp;Press</h2>
        <div className="flex flex-wrap place-content-evenly items-center justify-around gap-6 dark:invert">
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.recode.net/2017/3/9/14865882/resistbot-texts-faxes-senators-representatives"
          >
            <Img src={recode} alt="Recode" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.teenvogue.com/story/resistbot-faxes-texts-to-senators"
          >
            <Img src={teenVogue} alt="Teen Vogue" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.engadget.com/2017/03/09/resistbot/"
          >
            <Img src={engadget} alt="Engadget" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.fastcompany.com/90470629/why-resistbot-partnered-with-kind-snacks-to-promote-the-equal-rights-amendment"
          >
            <Img src={fastCompany} alt="Fast Company" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.theguardian.com/us-news/2017/mar/24/the-resistance-now-newsletter-resistbot-healthcare-bill"
          >
            <Img src={guardian} alt="The Guardian" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.huffingtonpost.com/entry/resistbot-faxes-politicians_us_58d8a426e4b03692bea70829"
          >
            <Img src={huffingtonPost} alt="The Huffington Post" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.good.is/articles/anti-trump-tech"
          >
            <Img src={good} alt="Good" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.bustle.com/p/resistbot-makes-contacting-your-representatives-easier-than-ever-before-47570"
          >
            <Img src={bustle} alt="Bustle" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://lifehacker.com/text-your-members-of-congress-with-resistbot-1794604660"
          >
            <Img src={lifehacker} alt="Lifehacker" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.romper.com/p/how-to-text-your-senator-about-the-zero-tolerance-policy-because-you-need-to-do-something-9509585"
          >
            <Img src={romper} alt="Romper" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.businessinsider.com/resistbot-overloaded-users-fax-congress-about-gop-healthcare-2017-9"
          >
            <Img src={businessInsider} alt="Business Insider" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.bna.com/wanna-join-trump-b57982085087/"
          >
            <Img src={bna} alt="Bloomberg BNA" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="http://www.miamiherald.com/news/nation-world/national/article189513934.html"
          >
            <Img src={miamiHerald} alt="Miami Herald" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://theskimm.com/archive/2018-03-26"
          >
            <Img src={theSkimm} alt="The Skimm" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="http://www.wusa9.com/article/news/local/verify/verify-text-reveals-nra-political-contributions-to-elected-officials/65-522285283"
          >
            <Img src={wusa} alt="WUSA TV" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="http://pittsburgh.cbslocal.com/2017/05/03/pitt-grad-resistbot-congress/"
          >
            <Img src={kdka} alt="KDKA TV" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.instyle.com/news/how-you-can-make-your-voice-heard-amid-kavanaugh-investigation"
          >
            <Img src={inStyle} alt="InStyle" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.azcentral.com/story/news/politics/arizona/2019/05/17/app-called-resistbot-helps-critics-put-heat-ariz-republicans-martha-mcsally-david-schweikert/3707551002/"
          >
            <Img src={arizonaRepublic} alt="Arizona Republic" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.newsday.com/news/nation/impeachment-house-democrats-republicans-trump-w74605"
          >
            <Img src={newsday} alt="Newsday" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.adweek.com/brand-marketing/kind-uses-limited-edition-equality-bar-and-equality-bot-to-campaign-for-era/"
          >
            <Img src={adweek} alt="Adweek" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.billboard.com/music/pop/billie-eilish-launches-voting-initiative-9459092/"
          >
            <Img src={billboard} alt="Billboard" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.nme.com/news/music/billie-eilish-on-responsibility-to-discuss-politics-you-cant-ignore-it-and-you-cant-be-silent-2774478"
          >
            <Img src={nme} alt="NME" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.inc.com/minda-zetlin/americans-support-equal-rights-amendment-3-to-1.html"
          >
            <Img src={inc} alt="Inc." fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.snopes.com/fact-check/usps-50409/"
          >
            <Img src={snopes} alt="Snopes" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.khou.com/article/news/verify/verify-texting-usps-to-50409-will-send-letters-to-elected-officials/285-284bbb34-f3b5-40e7-8605-1674fdddd104"
          >
            <Img src={khou} alt="KHOU" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.oprah.com/inspiration/how-to-call-senators-representatives"
          >
            <Img src={oprah} alt="O Magazine" fill />
          </Anchor>
          <Anchor
            className="relative h-6 w-4/12 max-w-32 opacity-40 transition-opacity hover:opacity-100"
            href="https://www.salon.com/2020/09/24/text-messages-are-the-new-door-knocking--but-voters-are-paranoid-about-political-outreach-via-text/"
          >
            <Img src={salon} alt="Salon" fill />
          </Anchor>
        </div>
      </section>
      <Divider />
      <section className="flex flex-col flex-nowrap gap-4 text-center">
        <h2>Drive More Impact with the&nbsp;App</h2>

        <p className="mx-auto max-w-prose">
          Get the{' '}
          <Anchor className="anchor-text" href={config.miscUrls.iosApp}>
            iOS app
          </Anchor>{' '}
          and drive signatures for{' '}
          <Anchor className="anchor-text" href="/petitions">
            top campaigns
          </Anchor>{' '}
          with your&nbsp;contacts.
        </p>
        <div className="iphone-container gap-2 px-10">
          <Anchor href={config.miscUrls.iosApp}>
            <div className="rounded bg-gradient-to-tr from-indigo-500 to-purple-500 p-4 text-white shadow duration-300 hover:shadow-lg">
              <p className="text-xl">20,680</p>
              <p className="text-xs uppercase tracking-widest text-white/50">
                signers
              </p>
              <p className="text-base font-bold leading-snug tracking-tight">
                Vote Yes for Jan. 6th Commission
              </p>
              <p className="line-clamp-4 text-xs text-white/50">
                We urge you to support a 9/11-style Commission charged with
                investigating and reporting on the facts and causes relating to
                the January 6, 2021, attack on the United States Capitol which
                sought to interrupt the peaceful transfer of power. The
                Commission’s mandate should include investigation of the facts
              </p>
            </div>
          </Anchor>
          <Anchor href={config.miscUrls.iosApp}>
            <div className="rounded bg-gradient-to-tr from-indigo-500 to-purple-500 p-4 text-white shadow duration-300 hover:shadow-lg">
              <p className="text-xl">5,120</p>
              <p className="text-xs uppercase tracking-widest text-white/50">
                signers
              </p>
              <p className="text-base font-bold leading-snug tracking-tight">
                Abolish the Filibuster
              </p>
              <p className="line-clamp-4 text-xs text-white/50">
                The fillibuster is antidemocratic. At this point it stands
                directly in the way of voting rights and national security by
                blocking the bill to investigate the 1/6 insurrection. There is
                neither a political nor an ideological reason to maintain it.
                Democrats have gone out of their way in the interest of
                bipartisan cooperation for the creation of a commission to
                investigate the insurrection.
              </p>
            </div>
          </Anchor>
        </div>
        <Anchor href={config.miscUrls.iosApp}>
          {prefersDark ? (
            <Img
              src={appStoreDark}
              alt="Download on the App Store"
              height={40}
              width={120}
              style={{ display: 'block', margin: '0 auto' }}
            />
          ) : (
            <Img
              src={appStore}
              alt="Download on the App Store"
              height={40}
              width={120}
              style={{ display: 'block', margin: '0 auto' }}
            />
          )}
        </Anchor>
      </section>
      <div className="full-bleed mb-5 mt-10 h-20 border-y-4 border-stone-200 bg-stone-500 dark:border-stone-700">
        <Img
          src={frontPages}
          alt="Image of many newspaper front pages tiled"
          fill={true}
          style={{ objectFit: 'cover', mixBlendMode: 'overlay' }}
        />
      </div>
      <section className="flex flex-col flex-nowrap gap-4 text-center">
        <h2>See your Letter in Your Local&nbsp;Newspaper</h2>
        <p className="mx-auto max-w-prose">
          We’ve publicly shamed and held your elected officials accountable in{' '}
          <Anchor className="anchor-text" href="/letters-to-the-editor">
            thousands of local newspapers
          </Anchor>{' '}
          in 49 states and D.C. since 2017.
        </p>
        <div className="iphone-container mb-10">
          <p className="max-w-56 rounded-lg bg-white p-4 text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <span role="img" aria-label="Newspaper">
              📰
            </span>{' '}
            Your letter has been nominated for a letter to the editor! Do you
            want to submit it?
          </p>
          <p className="max-w-56 self-end rounded-lg bg-blue-600 p-4 text-xs text-white shadow">
            <span role="img" aria-label="Thumbs Up">
              👍
            </span>{' '}
            Yes!
          </p>
          <p className="max-w-56 rounded-lg bg-white p-4 text-xs text-black shadow dark:bg-stone-800 dark:text-white">
            <span role="img" aria-label="Smiling Face">
              ☺️
            </span>{' '}
            Great! What newspaper would you like me to send it to?
          </p>
        </div>
      </section>
      <section>
        <Merch items={merch} />
      </section>
      <Divider className="mt-0" />
      <section className="text-center">
        <h2>Give it a try!</h2>
        <p className="mx-auto mb-2 max-w-prose">
          The easiest and quickest way to hold your officials accountable
          since&nbsp;2017.
        </p>
        <ResistCTAButtons copy="Chat with Resistbot" />
      </section>
    </Layout>
  );
};

Home.propTypes = {
  merch: PropTypes.arrayOf(MerchType),
  posts: PropTypes.arrayOf(BlogPostTileType),
  trendingPetitions: PropTypes.arrayOf(CampaignTypeList),
  siteStats: SiteStatsType,
};

export default Home;
