import PropTypes from 'prop-types';
import Anchor from './typography/anchor';

/**
 * Displays a notable quote from an author, optionally with some descriptions about the author themselves and/or a link to more information.
 */
const AuthorQuote = ({
  author,
  children,
  href,
  position,
  station,
  className,
}) => {
  const [AuthorComponent, authorProps] = href
    ? [Anchor, { href, rel: 'noreferrer', className: 'anchor-subtle' }]
    : ['span'];

  return (
    <figure className={className}>
      <blockquote className="font-optical-italic max-w-prose">
        “{children}”
      </blockquote>
      <figcaption className="mt-2 text-sm uppercase tracking-wider">
        <AuthorComponent {...authorProps}>
          <strong>{author}</strong>
          {position && <>, {position} </>}
          {station && (
            <>
              <em>of</em> {station}
            </>
          )}
        </AuthorComponent>
      </figcaption>
    </figure>
  );
};

AuthorQuote.propTypes = {
  /**
   * Who said it?
   */
  author: PropTypes.string.isRequired,
  /**
   * Any content to enclose, usually plain text
   */
  children: PropTypes.node.isRequired,
  /**
   * A link to the source of the quote if available
   */
  href: PropTypes.string,
  /**
   * The position at an org this person is at
   */
  position: PropTypes.string,
  /**
   * The org this person represents
   */
  station: PropTypes.string,
};

export default AuthorQuote;
